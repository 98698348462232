<template>
    <div>
        <!--banner-->
        <banner banner-name="友情链接"
                :background-img="$store.getters.getBackground('link')"></banner>

        <v-card class="blog-container an-blog-InUp">
            <div class="link-title mb-6">
                <v-icon color="blue">mdi-link-variant</v-icon>
                大佬链接
            </div>
            <v-row class="link-content">

                <v-col v-for="item in links" :key="item.id" cols="12" md="4" class="link-wrapper pa-2">
                    <a :href="item.link_url" class="link-info">
                        <v-avatar class="link-img" size="65px">
                            <img :src="item.link_image_url" alt="">
                        </v-avatar>
                        <a :href="item.link_url">
                            <div class="link-name">{{ item.link_name }}</div>
                            <div class="link-intro">{{ item.desc }}</div>
                        </a>
                    </a>
                </v-col>

            </v-row>
            <div class="link-title  mt-5 mb-4">
                <v-icon color="blue">mdi-dots-horizontal-circle</v-icon>
                添加友链
            </div>
            <blockquote>
                <div>名称：SuperCow</div>
                <div>简介：但行好事，莫问前程</div>
                <div>头像：https://boke-dashang.obs.cn-southwest-2.myhuaweicloud.com/13b61db6f37161a69d4f8abba163592.jpg</div>
            </blockquote>
            <div class="mt-5 mb-5">
                需要交换友链的可在下方留言💖
            </div>
            <blockquote>
                <div>友链信息展示需要，你的信息格式要包含：名称、介绍、链接、头像</div>
            </blockquote>
            <div class="split-line"></div>
            <!--评论-->
            <div class="link-comment-container">
                <div class="link-title mb-6">
                    <v-icon color="blue">mdi-comment</v-icon>
                    评论
                </div>
                <comment
                        @commentsSuccess="commentsLinkSuccess"
                        :comments_type="comments_type"></comment>
            </div>
            <div class="link-comment-content-container">
                <comment-list
                        @nextPageData="nextLinkPageData"
                        @nextChildrenPageData='nextLinkChildrenPageData'
                        :comments_type="comments_type"
                        :comments-page-info="articlesCommentsData.pageInfo"
                        :comment-data='articlesCommentsData.data'
                />
            </div>
        </v-card>

    </div>
</template>

<script>
  import Banner from "@/components/banner/Banner";
  import Comment from '@/components/comment/comment'
  import CommentList from '@/components/comment/commentList'
  import {getFriendChain} from "@/network/interface";
  import {CommentsDataMixin} from "@/components/CommentMixin"

  export default {
    name: "Link",
    mixins: [CommentsDataMixin],
    data() {
      return {
        links: [],
        comments_type: 1,
      }
    },
    created() {
      getFriendChain().then(res => {
        this.links = res.data
      })

      // 获取评论数据
      this.getCommentsList({comments_type: this.comments_type})

    },
    methods: {
      // 获取下一页文章评论数据
      nextLinkPageData() {
        const params = {
          comments_type: this.comments_type,
          pageNum: this.articlesCommentsData.pageInfo.pageNum + 1
        }
        this.nextPageData(params)
      },
      // 获取下一页子评论数据
      nextLinkChildrenPageData(father_id, pageNum) {
        this.nextChildrenPageData(father_id, pageNum, this.comments_type, {})
      },
      commentsLinkSuccess(data){
        this.getCommentsList({comments_type: this.comments_type})
      }
    },
    components: {
      Banner,
      Comment,
      CommentList
    }
  }
</script>

<style scoped>

    blockquote {
        line-height: 2;
        margin: 0;
        font-size: 15px;
        border-left: 0.2rem solid #49b1f5;
        padding: 10px 1rem !important;
        background-color: #ecf7fe;
        border-radius: 4px;
    }


    .link-title {
        font-size: 1.3rem;
        font-weight: 700;
        color: #344c67;
    }

    .link-wrapper {
        position: relative;
        border-radius: 8px;
        transition: all 0.5s;
    }

    .link-info {
        display: flex;
        align-items: center;
    }


    .link-wrapper::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #49b1f5;
        border-radius: 8px;
        transform: scale(0);
        transition: all 0.5s;
    }


    .link-wrapper a {
        text-align: center;
        flex: 1;
    }


    .link-img {
        transition: all 0.5s;
    }


    .link-name {
        position: relative;
        font-size: 1.2rem;
        font-weight: 700;
        transition: all 0.5s;

    }

    .link-intro {
        position: relative;
        font-size: 0.8rem;
        padding: 16px 10px;
        transition: all 0.5s;
    }

    .link-wrapper:hover {
        box-shadow: 2px 2px 10px rgba(73, 177, 245, 0.8);
    }

    .link-wrapper:hover::before {
        transform: scale(1);
    }

    .link-wrapper:hover .link-img {
        transform: rotate(360deg);
    }

    .link-wrapper:hover .link-name,
    .link-wrapper:hover .link-intro {
        color: #eeeeee;
    }


    .link-comment-container {
        position: relative;
        margin-top: 25px;
    }

    .split-line {
        border-top: 2px dashed #d2ebfd;
        border-bottom: 2px dashed #d2ebfd;
        margin: 20px 0;
    }

    .link-comment-content-container {
        padding-top: 25px;
    }


    .theme--dark blockquote {
        background-color: #474545;
    }

    .theme--dark .link-name {
        color: #ffffff;
    }

    .theme--dark .link-intro {
        color: #ffffff;
    }


    .theme--dark .link-title {
        color: #eeeeee;
    }

</style>
